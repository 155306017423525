.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Myriad Pro';
  color: #7c7a7a;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Myriad Pro';
  font-size: 18px;
}

#welcome-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url('images/7a_background.jpg');
  background-size: 65%;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.5s ease;
}

.welcome-page.hovered {
  opacity: 0.5;
}


#welcome-page .click-area {
  position: absolute;
  width: 15%;
  height: 15%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#welcome-page .click-area:hover ~ #welcome-page {
  opacity: 0.5;
}

#horizontal-menu-wrapper .click-area {
  position: absolute;
  width: 5%;
  height: 14%;
  left: 47.5%;
  max-height: 100px;
}

#page-body {
  display: grid;
  max-width: 70%;
  margin: auto;
  padding-top: 64px;
  align-items: center;
  justify-content: center;
}

.home_page_images img {
  margin: 2px;
}

.home_page_images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1290px;
}

.grayscale-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  filter: grayscale(100%);
  flex-basis: 16.66667%;
  transition: filter 0.5s ease-in-out;
  transform-origin: center center;
  /* transform: scale(1); */
}

/* grayscale-image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
} */

.grayscale-image:hover {
  filter: grayscale(0%);
}

.image-title {
  position: absolute;
  opacity: 0;
  border-radius: 4px;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #e1e2deee;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}

.grayscale-image:hover .image-title {
  opacity: 1;
}

#horizontal-menu-wrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end ;
  margin-top: 70px;
}

#horizontal-menu-wrapper .logo {
  height: 110px;
  align-self: center;
  width: 100%;
  max-width: 70%;
  background-image: url('images/7a_logo.jpg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.horizontal-menu {
  margin-top: -20px;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.horizontal-menu li {
  margin-right: 2px;
}

.horizontal-menu li:last-child {
  margin-right: 0;
}

.horizontal-menu li a {
  text-decoration: none;
  color: #c1bfbf;
  border-radius: 4px;
  font-weight: 450;
  font-size: larger;
  font-family: 'Myriad Pro';
  letter-spacing: 2px;
}

.horizontal-menu li a:hover, .horizontal-menu li.active a {
  color: rgb(73, 73, 72); 
}

.project-detail-page {
  display: grid;
  grid-template-columns: 2fr 3fr; 
  gap: 20px; 
  overflow: hidden;
  box-sizing: border-box;
}

.project-details {
  font-weight: lighter;
  text-align: left;
}
.project-details h1 {
  color: #04182b;
  letter-spacing: 2px;
  font-family: 'Myriad Pro';
  font-size: x-large;
}
.project-details p.label:first-of-type {
  margin-bottom: 20px;
}
.project-details p.label {
  margin: 0;
  font-size: medium;
  letter-spacing: 1px;
  color: #7c7a7a;
  font-family: 'Myriad Pro';
}
.project-details p.label .header {
  font-weight: bold;
}
.project-details-body {
  margin-top: 20px;
  font-weight: lighter;
  text-align: left;
  height: 200px;
  overflow-y: auto;
}

.project-details-body::-webkit-scrollbar {
  width: 0.25em;
}

.project-details-body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.project-details-body::-webkit-scrollbar-thumb {
  background-color: #c5c1c1;
  border-radius: 4px;
}

.project-details-body::-webkit-scrollbar-thumb:hover {
  background-color: #727171;
  border-radius: 4px;
}

.project-details-body p {
  overflow: hidden;
  font-size: medium;
  letter-spacing: 1px;
  color: #7c7a7a;
  font-family: 'Myriad Pro';
}
.project-image {
  max-width: 100%;
  overflow: hidden;
}
.project-image, .project-image img {
  border-radius: 4px;
  max-height: 500px;
  height:500px;
}

.project-all-images {
  grid-column: 1 / span 2; /* Span two columns */
  width: 75%;
  margin: auto;
}
.thumbnail-images-container {
  display: flex;
}
.thumbnail-container {
  width: 90px;
}
.thumbnail-image {
  width: 95%;
  max-width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 2px;
  transition: filter 0.5s ease;
  border: 2px solid transparent;
}

.thumbnail-image:hover {
  filter: grayscale(100%);
  cursor: pointer;
  border-color: rgb(162, 160, 163);
}


.contact-page {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 20px;
}

.contact-detail-wrapper {
  text-align: left;
  font-size: medium;
  font-weight: lighter;
}
.contact-detail-wrapper p {
  font-size: medium;
  letter-spacing: 1px;
  color: #7c7a7a;
  font-family: 'Myriad Pro';
  margin-top: 5px;
}
.contact-detail-wrapper h4 {
  font-size: large;
  letter-spacing: 2px;
  color: #7c7a7a;
  font-family: 'Myriad Pro';
  margin-bottom: 0;
}

.form-label {
  margin-bottom: 0;
}

.form-name input {
  width: 48%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 4px;
  background-color: #fcfafa;
}

.form-email input {
  width: 97%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 4px;
  background-color: #fcfafa;
}

.form-label {
  font-weight: 500;
  font-size: small;
}

.form-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 5px;
}

.form-right textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 4px;
  background-color: #fcfafa;
}

.arrow {
  width: 20px;
  height: 20px;
  background-color: #f00;
  transform: rotate(45deg);
}

/* button.slick-arrow {
  background: #acacb9;
  border-radius: 2px;
  width: 30px;
  height: 30px;
} */

button.slick-prev {
  left: -35px;
}

button.slick-next {
  right: -25px;
}

button.slick-prev:before, button.slick-next:before {
  font-size: 30px;
  color: #494848;
}
.slick-slider {
  display: inline-block;
}
.project-all-images .slick-track {
  display: flex;
  justify-content: center;
}
.slick-slide {
  max-width: 90px;
}
.slick-slide div {
  outline: none;
}

#admin-login {
  margin: auto;
  margin-top: 150px;
}
#login-wrapper {
  border: solid 4px #6e6a68;
  border-radius: 4px;
  padding: 50px;
}
#login-wrapper h4 {
  margin-top: -15px;
}
#login-wrapper .form-group {
  text-align: left;  
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
#login-wrapper .form-group label {
  width: 100px;
}
#login-wrapper .form-group input {
  padding: 12px;
  border-radius: 4px;
}
#login-wrapper .form-group input:focus {
  /* Add your custom styles here */
  border-color: rgb(141, 141, 145);
  outline: none;
  box-shadow: 0 0 5px rgb(105, 105, 107);
}
#login-wrapper button  {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  font-weight: bold;
}
#login-wrapper button:hover {
  background-color: #555;
}
.submit-contact-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  width: 30%;
  max-width: 150px;
  font-weight: bold;
}
.submit-contact-btn:hover {
  background-color: #555;
}

#admin-wrapper {
  display: flex;
  width: 100%;
}
.admin-sidebar {
  width: 180px; 
  height: 100vh;
  background-color: #f0f0f0; 
  box-sizing: border-box;
  text-align: left;
}
.admin-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 180px;
  margin-top: 70px;
}
.admin-sidebar li {
  margin-bottom: 10px;
  width: 95%;
  padding: 4px 20px; 
  margin: 4px 5px;
  box-sizing: border-box;
  border-radius: 5px;
}
.admin-sidebar li:hover {
  background-color: #888a89;
}
.admin-sidebar a {
  text-decoration: none;
  color: #6b6969;
  font-size: large;
  font-weight: bold;
}
.admin-sidebar a:hover {
  color: #555;
}

.manage-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  align-items: center;
}
.manage-projects .wrapper {
  margin: 4px;
}
.manage-projects a {
  color: #4a98cf;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  margin: 4px;
  font-weight: bolder;
}
.manage-projects a:hover {
  color: #04213f;
}
.manage-projects {
  text-align: center;
  padding: 20px;
}
.project-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.project-item {
  background-color: #f3eeee;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}
.project-item:hover {
  background-color: #f2f2f2;
  transform: translateY(-2px);
}
.project-link {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}
.project-link:hover {
  color: #0056b3;
}
.project-text {
  font-size: 16px;
  font-family: 'Arial', sans-serif;
}

.form-container {
  max-width: 50%;
  width: 50%;
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.project-edit-container {
  max-width: 70%;
  width: 70%;
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.form-container h2,
.project-edit-container h2 {
  align-items: center;
  margin-bottom: 40px;
  color: #6e6a68;
}
.form-container div,
.project-edit-container div {
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.form-container label,
.project-edit-container label {
  flex: 1;
  margin-right: 10px;
  font-weight: bold;
  text-align: left;
  color: #6e6a68;
  font-size: medium;
}
.form-container input,
.form-container textarea,
.project-edit-container input,
.project-edit-container textarea {
  flex: 2;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.form-container input:focus,
.form-container textarea:focus,
.project-edit-container input:focus,
.project-edit-container textarea:focus {
  border-color: rgb(141, 141, 145);
  outline: none;
  box-shadow: 0 0 5px rgb(105, 105, 107);
}
.form-container button[type="submit"],
.project-edit-container button[type="submit"] {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 40px;
  font-weight: bolder;
  font-size: large;
  width: 40%;
}
.form-container button[type="submit"]:hover,
.project-edit-container button[type="submit"]:hover {
  background-color: #555;
}
.create-project-btn {
  background-color: #9e9c9c;
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 40px;
  font-weight: bolder;
  font-size: large;
  width: 40%;
  max-width: 300px;
}
.create-project-btn:hover {
  background-color: #555;
}
.project-edit-container .edit-project-btn-wrapper {
  width: 100%;
  justify-content: center;
}
.project-edit-container .cancel-project-btn {
  background-color: #aaa6a6;
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 40px;
  margin-left: 10px;;
  font-weight: bolder;
  font-size: large;
  width: 10%;
}
.project-edit-container .cancel-project-btn:hover {
  background-color: #555;
}
.project-edit-container .left-outer {
  width: 50%;
}
.project-edit-container .left-outer form {
  width: 80%;
}
.project-edit-container .right-outer {
  display: block;
  width: 50%;
}
.project-edit-container .right-outer #existed-images {
  display: flex;
  align-items: center;
  margin-top: 100px;
  flex-wrap: wrap;
}
#existed-images .image-container {
  padding: 5px;
  box-shadow: 0 0 5px rgb(105, 105, 107);
  margin: 4px;
  position: relative;
}
.image-container img {
  cursor: pointer;
}
.main-picture {
  box-shadow: 0 0 0 2px rgb(217, 7, 7);
}
.delete-checked {
  filter: grayscale(1);
}
.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  background: white;
  border-radius: 10px;
}
/* Additional styling for the delete icon */
.delete-button svg {
  stroke: #323c38;
  stroke-width: 1px;
  width: 25px;
  height: 25px;
}

/* Admin Contact List */
.contact-table {
  border-collapse: collapse;
  width: 70%;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
}
.contact-table th,
.contact-table td {
  /* border: 1px solid #ccc; */
  padding: 8px;
  text-align: left;
  height: 30px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  color: #7c7a7a;
}
.contact-table th {
  background-color: #f2f2f2;
  color: #7c7a7a;
  font-weight: bold;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.btn-update-order {
  margin-left: auto;
  background-color: #555;
  color: #fff;
  font-size: medium;
  font-weight: bold;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: pointer;
}
.btn-update-order:hover {
  background-color: #2a2929;
}



@media screen and (max-width: 1000px) {
  .project-detail-page {
    grid-template-columns: 1fr; /* Single column for small screens */
  }
  .project-all-images {
    grid-column: 1; /* Occupy the full width of the single column */
    width: 80%;
    margin: auto;
    max-width: 900px;
  }
  .project-details {
    width: 80%;
    margin: auto;
  }
  .project-image {
    height: auto;
    max-width: 100%;
    width: 100%
  }
}
